import * as CONST from "./const";
import axios from "axios";
import { Spinner } from '@chakra-ui/react'

export function getQueryVariable(name) {
  var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}
export function getLoginPage(data, state) {
  // data should be in stringified form
  // state should be in stringified form

  var url = CONST.S_URL + "/login";
  var next = {};
  var url_data = {};
  var curr_url_data = getAllUrlParams(window.location.href);
  if (data) {
    url_data["data"] = data;
  }
  if (state) {
    url_data["state"] = state;
  }
  url_data = Object.assign({}, url_data, curr_url_data);
  next["next_url"] = window.location.origin + window.location.pathname;
  next["url_data"] = url_data;
  url += "?next=" + JSON.stringify(next);
  window.location = url;
}
export function GetProfile(callback) {
  // return
  // const { userDetails, setuserDetails } = useStateContext();

  var config = {
    method: "get",
    url: getAPIUrl("/api/auth/profile/"),
    cache: false,
    headers: {
      Authorization: getAuthToken(),
    },
  };
  axios(config)
    .then(async function (res) {
      const tp = await res.data;
      if (tp.success) {
        var auth_dict = {
          eventGraphiaName: tp.name,
          eventGraphiaEmail: tp.email,
          eventGraphiaPhone: tp.phone_no,
          eventGraphiaCity: tp.user_city,
        };
        localStorage.setItem("auth_dict", JSON.stringify(auth_dict));
        if (callback) callback();
      }
    })
    .catch(function (tp) {
      getLoginPage();
      console.log(tp.message);
    });
}
export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      if (!typeof paramValue === "string")
        paramValue = JSON.stringify(paramValue);
      if (paramName) obj[paramName] = paramValue;
    }
  }

  return obj;
}
export function getAPIUrl(x) {
  return CONST.S_URL + x;
}

export function getAuthToken() {
  return "Token " + localStorage.getItem("eventGraphiaToken");
}

export function getLogoutFunctionality(e) {
  var logout = {
    method: "get",
    url: getAPIUrl("/api/auth/logout/"),
    headers: {
      Authorization: getAuthToken(),
    },
    contentType: "application/json; charset=utf-8",
  };

  axios(logout).then(function (response) {
    console.log(response);
    if (response.data.success) {
      localStorage.clear();
      getLoginPage();
    } else {
      alert(response.message);
    }
  });
}

export function isAdmin() {
  var status = process.env.REACT_APP_IS_ADMIN === "true";
  return status;
}

export function token_url(pre_url) {
  var url = new URL(pre_url);
  if (!localStorage.getItem("eventGraphiaToken")) {
    getLoginPage();
  }
  url.searchParams.set("token", localStorage.getItem("eventGraphiaToken"));
  return url.toString();
}

export function quotation_string(index) {
  var string1 = `Quotation `;
  var string2 = `v${index}`;
  var string = [string1, string2];
  return string;
}


export function getWhatsappShareUrl(e) {
  const shareLink = `https://wa.me/?text=${e}`
  return shareLink
}

export function globalLoader(size) {
  const defaultSize = 'xl'
  return (<Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size={size ? size : defaultSize} />)
}

export function isMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

export function defaultQuality() {
  const quality = 1080
  return quality
}

export class Platform {
  static get EG() {
      return 'eventgraphia';
  }

  static get WG() {
      return 'wedgraphia';
  }

  static get PLATFORM_LIST() {
      return [Platform.EG, Platform.WG];
  }

  static get currentPlatform() {
      return process.env.REACT_APP_PLATFORM;
  }

  static get isEG() {
      return Platform.currentPlatform === Platform.EG;
  }

  static get isWG() {
      return Platform.currentPlatform === Platform.WG;
  }
}

export function get_logo(black=false) {
  if(Platform.isEG){
    if(black) {
      return "https://assets.graphia.in/site_media/logo/eg_black.png"
    }
    return "https://assets.graphia.in/site_media/logo/logo.png"
  } else {
    if(black){
      return "https://assets.graphia.in/site_media/wedgraphia/logo/wg_black_website.png"
    }
    return "https://assets.graphia.in/site_media/wedgraphia/logo/wg_white_website.png"
  }
}
